@import "font.css";
@import "news.css";
@import "MegaMenu.css";

@import "bigSlider.css";
@import "typo.css";
@import "NavbarTop.css";
@import "Recomandation.css";
@import "footer.css";
@import "otherPage.css";
@import "tabs.css";
/* @import "responsive.css"; */
/* @import "NavMenuMobile.css"; */


body{
background-color: #eeeeee;
font-family: "Open sans", sans-serif;
font-weight: 500;
/* width: 100%; */
overflow-x: hidden;


/* font-style: italic; */
/* transition: all 0.5s; */

}