/* .RecContainer{
    background-color: white;
} */
.RecImg {
  margin-top: 15px;
  width: 110px;
  height: 90px;
  /* justify-content: center;
    align-items: center; */
}

.RecCard {
  width: 220px;
  height: 180px;
  cursor: pointer;
  box-shadow: 0 0 10px gray;
  display: inline-block;

  color: black;
}
.Kafedra {
  background-color: white;
  box-shadow: 0 0 15px gray;
}

.Kafedra .KafLink {
  text-decoration: none;
}
.Kafedra .KafLink span {
  color: black;
  font-family: "Open" sans-serif;
  font-weight: 600;
  font-size: 17px;
}

.Kafedra ul li {
  display: block;
  list-style: none;
  text-align: start;
  padding: 5px 20px;
  border-bottom: 2px solid #6d88f5;
  /* display: flex; */
  transition: all 0.3s;
}
.Kafedra ul li:hover {
  background-color: rgb(224, 219, 219);
}

.Kafedra ul {
  padding: 0;
}
