.ImgOtherNew {
  width: 100%;
}

.OtherTextNews {
  text-align: justify;
}

.ChairImg {
  width: 100%;
  height: 250px;
  object-fit: contain;
  border: 1px solid gray;
}

.ChairRow {
  padding: 10px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 10px gray;
}

.InfoMain {
  background-color: white;
  padding: 15px;
  /* border-radius: 15px; */
  box-shadow: 0 0 10px gray;
}

.InfoBradCamp {
  background-color: white;
  padding: 10px;
  /* border-radius: 15px; */
  box-shadow: 0 0 10px gray;
  margin-bottom: 15px;
  font-size: 16px;
}
.InfoBradCamp i {
  font-size: 13px;
}

.CategoryBrad {
  margin-right: 8px;
}

.SubCategoryBrad {
  margin-left: 8px;
}

.KafedraMudir {
  display: flex;
  background-color: #ffffff;
  padding: 5px;
  margin-bottom: 20px;
  height: 100px;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  box-shadow: 0 0 8px gray;
  border-radius: 5px;
  transition: all 0.3s;
}

.KafedraMudir:hover {
  transform: scale(1.05);
}

.KafedraMudir span {
  color: black;
  font-size: 20px;
}

.KafedraMudir span .Iobook {
  color: #314573;
}

.KafedraMudir .Ioschool {
  display: flex;
  font-size: 30px;
  color: #314573;
  text-align: center;
  justify-content: center;
}

.KafedraLink {
  text-decoration: none;
}

.KafedraImg {
  width: 100%;
  height: 250px;
  object-fit: cover;
  /* border: 1px solid gray; */
}

.KafedraRow {
  padding: 10px;
  background-color: white;
  border-radius: 10px;
}

.MegaMenuVer {
  top: 135px;
  position: sticky;
}


.NewsDetails{
  padding: 10px;
  background-color: white;
  border-radius: 10px;
}

.CardImgDetail{
width: 100%;
}

.Ioschool{
  text-align: center;
  justify-items: center;
}

/* .NavMenuVer {
  top: 0;
  position: sticky;
  background-color: aqua;
  /* padding: 20px; 
  z-index: 2400;
} */


.displayBlock{
  display: none;
}
