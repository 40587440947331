.slick-dots {
  top: 90%;
}
.slider-img {
  width: 100% !important;
  height: 380px;
}

.slick-slide {
 
  height: auto !important;
}

.SliderImg {
  object-fit: cover;
  height: 400px;
  width: 100%;
}

.SliderCard {
  position: relative;
  align-items: center;
}


/* .SliderNameBlock {
  position: absolute;
  width: 100%;
  height: 100px;
  z-index: 0;
  background-color: gray;
  opacity: 0.6;
  bottom: 0;
} */

.SliderName {
  bottom: 10px;
  position: absolute;
  color: white;
  font-size: 30px;
  justify-items: center;
  text-align: center;
  background-color: rgba(109, 136, 245, 0.5);
  width: 100%;
  z-index: 99999;
}

.SliderCard a{
  
  padding: 0;
  margin: 0;
}

/* .slider_a{
  transition: all 0.2s;
  font-size: 20px;
  color: white;
  text-transform: uppercase;
} */