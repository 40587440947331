.NavbarTop {
  display: block;
  /* position: relative; */
  /* overflow: hidden; */
}

.Desktop {
  position: sticky;
  z-index: 2010;
  top: 0;
}

 .NavbarTopRow {

}

.NavbarTop .LogoImg {
  background-color: rgb(255, 255, 255);
  width: 100px;
  cursor: pointer;
  margin-right: 8px;
  /* border-radius: 50%; */
}

.hemis {
  margin-right: 5px;
  width: 30px;
}

.NavbarTop .NavbarTopLink {
  display: flex;
  text-decoration: none;
  justify-items: center;
  align-items: center;
}

.NavbarTop .NavbarTopLink span {
  color: #ffffff;
  font-size: 14px;
  font-family: "Open" sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.2s;
}
.NavbarTop .NavbarTopLink span:hover {
  color: black;
}
.NavSearch {
  display: flex;
}

.NavSearch input {
  width: 100%;
}

.NavSearch button {
  border: none;
  background-color: #314573;
}
.NavSearch button .fa-search {
  font-size: 20px;
  padding: 10px;
  color: white;
}

.NavbarTop .container {
  display: inline-block;
}

.EduLink {
  display: flex;
  padding: 8px 25px;
  font-size: 18px;
  /* align-content: center; */
  background-color: #314573;
  text-decoration: none;
  color: white;
  justify-self: center;
}
.EduLinkm {
  display: flex;
  padding: 8px 20px;
  font-size: 18px;
  /* align-content: center; */
  background-color: #314573;
  text-decoration: none;
  color: white;
  justify-self: center;
}
.EduCol {
  display: flex;
  justify-content: center;
}
.homeTop {
  margin: 20px auto;
}

.NavMenu {
  background-color: #314573;
  box-shadow: 0 5px 5px gray;
  justify-items: center;
  align-items: center;
  padding: 0 20px;
  /* position: sticky;
  z-index: 2010;
  top: 0; */
}

.NavMenu .NavUl {
  /* justify-content: space-around; */
  padding: 0 15px;
  margin: 0;
}
 .NavUl li {
  display: inline-block;
  font-family: "Open" sans-serif;
  font-weight: bold;
  /* text-transform: uppercase; */
  padding: 10px 20px;
  transition: all 0.2s;
  position: relative;
  list-style: none;
}

 .NavUl li:hover {
  background-color: #e1e1e146;
}
 .NavUl li .NavMenuLink {
  display: flex;
  text-decoration: none;
  color: white;
  justify-items: center;
  align-items: center;
}

.NavUl li .NavMenuLink i {
  margin-left: 5px;
  color: white;
}
.NavUl .accardionContent {
  width: 300px;
  z-index: 1000;
  top: 42px;
  font-family: "Roboto";
  left: 0;
  font-weight: 600;
  font-size: 14px;
  position: absolute;
  display: none;
  transition: all 0.4s;

  box-shadow: 0 0 15px gray;
  border-top: 3px solid rgb(153, 255, 0);
  border-bottom: 3px solid rgb(153, 255, 0);
}

.accardionContent ul {
  padding: 0;
}
.accardionContent ul li {
  display: block;
  /* border-bottom: none; */
  list-style: none;
  padding: 8px 20px;
  background-color: #314573;

  border-bottom: 2px solid rgb(255, 255, 255) !important;
}
.accardionContent .accardionContentLink {
  text-decoration: none;
}

.accardionContent span {
  color: rgb(255, 255, 255) !important;
}
.accardionContent ul li:hover span {
  color: rgb(0, 0, 0) !important;
}
.accardionContent li:hover {
  background-color: #e8efff;
}

.accardion.open .accardionContent {
  display: block;
}

.accardion.open .fa-chevron-down {
  transform: rotate(180deg);
}

.simvol button {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 60%;
  padding: 4px 6px;
  border: 2px solid #6d88f5;
  background-color: transparent;
  font-family: "Open" sans-serif;
  font-weight: 600;
  background-color: rgba(51, 255, 112, 0.474);
  font-size: 20px;
}
.simvol img{
 width: 30px;
}
.simvolContent {
  display: none;
  /* display: flex; */
  width: 200px;
  z-index: 1005;
  transform: translateY(40%);
  top: 0;
  position: absolute;
  background-color: rgb(255, 255, 255);
}

.simvolContent img{
  width: 30px;
}

.simvol .simvolContent.openSimvol {
  display: block;
}

.fa-chevron-down.openSimvol {
  transform: rotate(180deg);
}

.simvol .simvolLink {
  text-decoration: none;
  color: black;
}

.simvol  ul li {
  width: 200px;
  list-style: none;
  padding: 5px 20px;
  text-align: left;
  text-transform: uppercase;
  border: 1px solid #6d88f5;
  background-color: greenyellow;
}
.simvol  ul {
  display: flex;
  /* width: 100%; */
  margin: 0;
  padding: 0;
}

.language {
  width: 100%;
}

.language select {
  padding: 8px 10px;
  width: 100%;

  font-family: "Open" sans-serif;
  font-weight: 600;
}

.simvollanguage{
  display: flex;
  align-self: space-around;
}

.simvollanguage img{
  margin-left: 10px;
  width: 40px;
}