/* .Container{
    width: 75%;
} */

.Card {
  padding: 3px;
  font-size: 18px;
  position: relative;
  box-shadow: 0 0 20px rgba(185, 184, 184, 0.63);
  /* height: 400px; */
}



.CardImg {
  /* width: 100%; */
  height: 212px;
  object-fit: cover;
}

.CardBody {
  /* position: absolute; */
  /* margin-top: 250px; */
}

.CardText {
  /* margin-bottom: 10px !important; */
  background-color: transparent;
  text-align: justify;
  font-family: "Roboto", sans-serif;
}

.Card p {
  padding: 0;
  margin-bottom: 5px;
  justify-content: center;
}

.CardData {
  /* border-radius: 3px; */
  /* color: #314573; */
  display: flex;
  font-weight: bold;
  justify-content: flex-start;
  align-items: center;
}

.HomeNewsBlock{
  position: relative;
  height: 370px;
}
.DataLinkBlock{
  color: #314573;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.DataLink {
  padding: 5px 15px;
  position: absolute;
  color: #314573;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  background-color:#eaeeff;
  bottom: 5px;
  left: 0;
  right: 0;
  /* margin-bottom: 0; */
  
}

.LinkNews {
  border-radius: 2px;
  /* position: absolute; */
  right: 25px;
  cursor: pointer;
  text-decoration: none;
  padding: 3px;
  /* color: black; */
  /* background-color: #6d88f5; */
}

.Block {
  display: flex;
  align-items: center;
  position: relative;
  /* height: 300px; */
}

.Block a {
  text-decoration: none;
  font-size: 20px;
  position: absolute;
  right: 15px;
  cursor: pointer;
  color: white;
  background-color: #314573;
  padding: 3px 20px;
}

.Block a:hover {
  background-color: #314573;
}

.Block::before {
  margin-left: 200px;
  position: absolute;
  content: "";
  width: 35%;
  height: 4%;
  background-color: #314573;
}

.Anons {
  /* overflow: scroll; */
  scroll-behavior: smooth;
  /* height: 780px; */
  padding: 3px;
  background-color: #ecefff;
  box-shadow: 0 0 20px rgba(185, 184, 184, 0.63);
}
.AnonsTextBlock {
  padding: 5px;
  transition: all 0.3s;
  /* box-shadow: 0 0 10px rgba(128, 128, 128, 0.279); */
}

.AnonsTextBlock:hover {
    padding: 5px;
    box-shadow: 0 0 10px rgba(128, 128, 128, 0.279);
  }

.AnonsText {
  text-align: justify;
  /* background-color:rgba(202, 243, 255, 0.63); */
  padding: 3px;
  /* box-shadow: 0 0 10px rgba(128, 128, 128, 0.279); */
}
.AnonsLink {
  text-decoration: none;
  color: black;
}

.AnonsLink:hover {
  color: black;
}

.AnonsBlock {
  display: flex;
  align-items: center;
  position: relative;
}

.AnonsBlock a {
  text-decoration: none;
  font-size: 20px;
  position: absolute;
  right: 15px;
  cursor: pointer;
  color: white;
  background-color: #314573;
  padding: 3px 20px;
}

.AnonsBlock a:hover {
  background-color: #6d88f5;
}
.AnonsText {
  font-size: 18px;
}
