.accardionMenu {
  background-color: white;
  width: 100%;
  height: 400px;
  font-family: "Roboto";
  font-weight: 600;
  font-style: italic;
  font-size: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  box-shadow: 0 0 10px gray;
  /* scrollbar-width: 5px; */
}

::-webkit-scrollbar{
  width: 10px;

}
::-webkit-scrollbar-thumb{
  background-color: #6d88f5;
}
::-webkit-scrollbar-track{
  background-color: #dddddd;
}

.accardinTitle {
  margin: 0;
  padding: 0;
}
.accardinTitle .accardion {
  margin: 0;
  padding: 10px 20px;
  list-style: none;
  border-bottom: 1px solid gray;
  transition: all 0.2s;
  /* display: block; */
}

.accardion:hover {
  background-color: #eeeeee;
}

.acardionMenuLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  text-decoration: none;
}
.acardionMenuLink span {
  display: flex;
}

.fa-chevron-down {
  color: #6d88f5;
  justify-self: right;
  transition: all 0.3s;
}

.acardionMenuLink p {
  margin-top: auto;
  margin-bottom: auto;
  color: #001567;
}

.acardionMenuLink img {
  margin-right: 12px;
  width: 35px;
}

.accardionMenu .accardionContentver {
  font-family: "Roboto";
  font-weight: 600;
  font-style: italic;
  position: relative;
  transition: overflow 0.6s;
  margin-left: 50px;
  height: 0;
  overflow: hidden;
  /* background-color: #f2f2f2; */
}

/* .active {
    display: block;
} */

.accardionContentver ul {
  padding: 0;
}
.accardionContentver ul li {
  border-bottom: none;
  list-style: none;
  padding: 8px;
}
.accardionContentver ul li:hover {
  background-color: white; 
}

.accardionContentver ul li .accardionContentLink {
  text-decoration: none;
  /* color: black; */
}
.accardionContentver ul li .accardionContentLink span {
  color: #001567;
}
.accardion.open .accardionContentver {
  height: auto;
  display: block;
}

.accardion.open   .fa-chevron-down {
  transform: rotate(180deg);
}
