.footer {
  background-color: #314573;
  padding: 20px 0;
 
}

.footer h4 {
  /* color: black!important; */
  font-family: "Open" sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}
.footer p {
  /* color: black!important; */
  text-align: justify;
  font-family: "Open" sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

.footer i,
h5 {
  /* color: black!important; */
  cursor: pointer;
}

.footer .contact i {
  font-size: 20px;
  margin-top: 4px;
  margin-left: 2px;
  transition: 0.3s;
}

.footer .phone {
  margin-right: 10px;
  text-align: center;
  width: 30px;
  height: 30px;
  border: 1px solid white;
  transition: 0.3s;
}

.footer .phone:hover {
  background-color: white;
}

.footer .social {
  margin-right: 10px;
  text-align: center;
  width: 30px;
  height: 30px;
  border: 1px solid white;
  transition: 0.3s;
}

.footer .social:hover {
  background-color: white;
}

.footer .phone:hover i {
  color: #1a133c;
}

.footer .contact:hover .fa-youtube {
  color: #ff0000;
  transform: rotate(360deg);
}
.footer .contact:hover {
  background-color: rgba(128, 128, 128, 0.527);
}

.footer .contact:hover .fa-telegram-plane {
  color: #28a7e8;
  transform: rotate(360deg);
}

.footer .contact:hover .fa-facebook-f {
  color: #4064ac;
  transform: rotate(360deg);
}

.footer .contact:hover .fa-instagram {
  color: #df4674;
  transform: rotate(360deg);
}

.about{
  font-size: 12px;
}